<template>
  <div>
    <Content :style="{ padding: '0 24px 16px' }">
      <div class="main-body">
        <div class="select-two main-search">
          <Select
            v-model="bytextType"
            style="width:110px"
            @on-change="resetGetInfo"
          >
            <Option
              v-for="item in textTypes"
              :value="item.typeValue"
              :key="item.typeValue"
              >{{ item.type }}</Option
            >
          </Select>
          <Input
            prefix="ios-search"
            v-model="text"
            placeholder="请输入搜索内容"
            style="width: 280px"
            @on-enter="resetGetInfo"
          />

          <!-- <div class="st-block ml10">
               <div class="st-label">状态:</div>
               <div class="st-select">
                    <Select v-model="byType"  @on-change="resetGetInfo">
                          <Option value="0">全部</Option>
                        <Option  value="1" >待保养</Option>
                        <Option  value="2" >已保养</Option>
                    </Select>
               </div>
            </div> -->
          <div class="st-block ml10">
            <div class="st-label">所属车间</div>
            <div class="st-select">
              <Select v-model="workshop" @on-change="resetGetInfo">
                <Option value="-1">全部</Option>
                <Option
                  v-for="(item, index) in workshops"
                  :value="item.workshopId"
                  :key="index"
                  >{{ item.workshop }}</Option
                >
              </Select>
            </div>
          </div>
          <div class="st-block ml10">
            <div class="st-label">保养时间</div>
            <div class="st-datepicker">
              <DatePicker
                v-model="workData"
                format="yyyy-MM-dd"
                type="daterange"
                placement="bottom-end"
                placeholder="请选择保养时间"
                @on-change="resetGetInfo"
              ></DatePicker>
            </div>
          </div>
        </div>
        <div class="btn-area">
          <div class="btn-block" @click="exportData">
            <i class="icon1 icondaochu"></i>
            &nbsp;&nbsp;导出数据
          </div>
        </div>
        <div class="table-area">
          <Table
            class="devTable"
            max-height="750"
            border
            ref="selection"
            no-data-text=" "
            :loading="loading"
            :columns="theadDate"
            :data="tableDate"
          ></Table>
        </div>
        <div class="main-page">
          <Page
            :total="total"
            show-sizer
            show-elevator
            show-total
            :page-size="pageSize"
            :current="pageNum"
            @on-page-size-change="pagesizechange"
            @on-change="pagenumberchange"
          />
        </div>
      </div>
    </Content>
  </div>
</template>

<script>
export default {
  data() {
    return {
      workshop: "-1",
      workshops: [],
      text: "", // 非必需，搜索内容
      byType: "0", // 非必需，保养状态：1待保养，2已保养',
      // byCode:"",
      // byCreattimme:"",
      // byDateplan:"",
      // deviceId:"",
      // deviceNum:"",
      // byContent:"",
      bytextType: "1", // 非必需，搜索内容的类型
      textTypes: [],
      pageSize: 10,
      pageNum: 1,
      total: 0,
      pages: 0,
      workData: "",
      // 顶部筛选
      devSearchLists: [
        {
          value: "0",
          label: "设备型号"
        },
        {
          value: "1",
          label: "设备类型"
        },
        {
          value: "2",
          label: "生产厂家"
        }
      ],
      devSearchList: "", // 顶部第一个select选择的东西
      searchText: "", // 顶部搜索内容
      theadDate: [
        {
          title: "序号",
          render: (h, params) => {
            return h(
              "span",
              params.index + (this.pageNum - 1) * this.pageSize + 1
            );
          },
          align: "center",
          width: 48
        },

        {
          title: "保养单号",
          key: "byCode",
          className: "blueFont",
          minWidth: 125
        },
        {
          title: "设备编号",
          key: "serialNumber",
          className: "",
          minWidth: 80
        },
        {
          // title: '设备编号',
          title: "机台号",
          key: "deviceNum",
          minWidth: 60
        },
        {
          title: "计划保养日期",
          key: "byDateplan",
          minWidth: 110
        },
        {
          title: "制单人",
          key: "creatname",
          minWidth: 95
        },
        {
          title: "制单日期",
          key: "byCreattimme",
          minWidth: 90
        },
        {
          title: "保养人",
          key: "dealname",
          minWidth: 95
        },
        {
          title: "实际保养日期",
          key: "byDealtime",
          minWidth: 110
        }
      ],
      tableDate: [],
      loading: false
    };
  },
  methods: {
    resetGetInfo() {
      this.pageSize = 10;
      this.pageNum = 1;
      this.searchList();
    },
    // 修改每页条数
    pagesizechange(pagesize) {
      this.pageSize = pagesize;
      this.pageNum = 1;
      this.searchList();
    },
    // 修改页码
    pagenumberchange(pagenum) {
      this.pageNum = pagenum;
      this.searchList();
    },
    // 导出数据
    exportData() {
      const that = this;
      window.location.href =
        that.$api.baseUrl +
        "/iext/back/device/DeviceController/exportDeviceMaintainHistory";
    },
    // 当查询条件变化时调用
    searchList() {
      const that = this;
      var factoryDate1 = "";
      var factoryDate2 = "";
      if (that.workData[0] !== "") {
        var d = new Date(that.workData[0]);
        var d1 = new Date(that.workData[1]);
        factoryDate1 =
          d.getFullYear() +
          "-" +
          that.p(d.getMonth() + 1) +
          "-" +
          that.p(d.getDate());
        factoryDate2 =
          d1.getFullYear() +
          "-" +
          that.p(d1.getMonth() + 1) +
          "-" +
          that.p(d1.getDate());
      }
      this.axios({
        url:
          "/iext/back/device/DeviceController/queryDeviceMaintainHistoryList",
        method: "get",
        params: {
          searchText: that.text, // 非必需，搜索内容
          // byType: that.byType, // 非必需，保养状态：1待保养，2已保养',
          searchType: that.bytextType, // 非必需，搜索内容的类型
          byplanTime1: factoryDate1,
          byplanTime2: factoryDate2,
          pageSize: that.pageSize,
          pageNum: that.pageNum,
          workshop: that.workshop === "-1" ? "" : that.workshop
        }
      })
        .then((res) => {
          if (res.data.success === 1) {
            that.tableDate = res.data.body.list.result;
            that.total = res.data.body.list.total;
            that.pages = res.data.body.list.pages;
            that.pageSize = res.data.body.list.pageSize;
            that.pageNum = res.data.body.list.pageNum;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // p为不够10添加0的函数
    p(s) {
      return s < 10 ? "0" + s : s;
    },
    getTextType() {
      this.axios({
        url: "/iext/back/device/DeviceController/searchType",
        method: "get",
        params: {}
      })
        .then((res) => {
          if (res.data.success === 1) {
            this.textTypes = res.data.body.searchType;
            this.searchList();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 获取车间列表
    getWorkshops() {
      const that = this;
      this.axios({
        url: "/iext/back/device/DeviceController/workshoplist",
        method: "get",
        params: {}
      })
        .then((res) => {
          if (res.data.success === 1) {
            that.workshops = res.data.body.workshoplist;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  },
  created() {},
  mounted() {
    this.getTextType();
    this.getWorkshops();
  }
};
</script>

<style lang="scss" scoped>
.main-body {
  width: 100%;
  height: 100%;
  // background: rgba(228,235,241,1);
  padding-bottom: 0;
  .select-one {
    margin: 16px 0;
  }
  .select-two {
    margin-top: 16px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    .st-block {
      height: $newIpt-height;
      display: flex;
      .st-label {
        margin-right: 10px;
      }
      .st-select {
        width: 108px;
        height: 3$newIpt-height;
      }
      .st-datepicker {
        width: 240px;
        height: $newIpt-height;
      }
    }
    .ml10 {
      margin-left: 10px;
    }
  }
  .btn-area {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    .btn-block {
      display: flex;
      align-items: center;
      padding: 10px;
      cursor: pointer;
    }
    .btn-block:hover {
      display: flex;
      align-items: center;
      padding: 10px;
      cursor: pointer;
      color: #2980b9;
    }
  }
  .table-area {
    .devTable {
      table {
        // position: fixed !important;
      }
    }
    .ivu-table-wrapper /deep/ .ivu-table {
      .ivu-table-body {
        // overflow-y: scroll;
        //     max-height: 560px;
        //     overflow-x: inherit;

        // position: fixed !important;
        tbody .ivu-table-row:hover {
          td .ivu-table-cell .ivu-select .ivu-select-selection {
            border: none;
            box-shadow: none;
            background: rgba(235, 247, 255, 1);
          }
          td .ivu-table-cell .ivu-btn {
            border: none;
            box-shadow: none;
            background: rgba(235, 247, 255, 1);
          }
        }

        tbody .ivu-table-row {
          td .ivu-table-cell .ivu-select .ivu-select-selection {
            border: none;
            box-shadow: none;
            .ivu-select-selected-value {
              padding-left: 0;
            }
          }
          td .ivu-table-cell div {
            border: none;
            box-shadow: none;
            padding: 0px;
          }
          .blueFont {
            color: rgba(87, 163, 243, 1);
          }
        }
      }
    }
  }
}
</style>

<style lang="scss">
.mainbody {
  .ivu-tabs-nav-container {
    /*background:rgba(228,235,241,1)*/
  }
  .el-tree-node__content {
    width: 100%;
    min-height: 50px;
    border-bottom: 1px solid rgba(223, 227, 232, 1);
  }
}
</style>
